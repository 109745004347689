import { useMemo } from 'react';

import { useDependency } from './useDependency';

import { queryCache, useMutation as useMutationHook } from 'react-query';

// ...

export const useMutation = () => {
  const entityService = useDependency('entityService');

  return useMemo(() => {
    const mutations = {
      useSearch() {
        return useMutationHook(query => entityService.search(query));
      },

      useInvite() {
        return useMutationHook(({ vendor, email }) => entityService.invite(vendor, email), {
          throwOnError: true,
        });
      },

      // ...

      useCreateProduct(data) {
        return useMutationHook(
          data => {
            return entityService.createProduct(data);
          },
          {
            onSuccess(created) {
              queryCache.setQueryData(['product', created.id], created);
            },
          },
        );
      },

      useUpdateProduct(id) {
        return useMutationHook(
          async data => {
            if (data.image) {
              const publicUrl = await entityService.upload('products', id, data.image);

              return entityService.updateProduct(id, {
                image: publicUrl,
              });
            }

            return entityService.updateProduct(id, data);
          },
          {
            onSuccess() {
              queryCache.invalidateQueries(['product', id]);
            },
          },
        );
      },

      useCreateContent(data) {
        return useMutationHook(data => {
          return entityService.createContent(data);
        });
      },

      useUpdateContent(id) {
        return useMutationHook(
          async data => {
            if (data.image) {
              const publicUrl = await entityService.upload('content', id, data.image);

              return entityService.updateContent(id, {
                image: publicUrl,
              });
            }

            if (data.mediaUrl) {
              const publicUrl = await entityService.upload('content', id, data.mediaUrl);

              return entityService.updateContent(id, {
                mediaUrl: publicUrl,
              });
            }

            return entityService.updateContent(id, data);
          },
          {
            onSuccess() {
              queryCache.invalidateQueries(['content', id]);
            },
          },
        );
      },

      usePublishProduct() {
        return useMutationHook(({ id }) => entityService.publishProduct(id), {
          onSuccess(_, variables) {
            // return promise to await refetch of product.
            return queryCache.invalidateQueries(['product', variables.id]);
          },
        });
      },

      usePublishContent() {
        return useMutationHook(({ id }) => entityService.publishContent(id), {
          onSuccess(_, variables) {
            // return promise to await refetch of show.
            return queryCache.invalidateQueries(['content', variables.id]);
          },
        });
      },
    };

    return new Proxy(mutations, {
      get(target, propKey) {
        return (...args) => {
          const [mutate, { status }] = target[propKey].apply(target, args);

          return [mutate, status];
        };
      },
    });
  }, [entityService]);
};
