import { useContext } from 'react';

import { useDropzone } from 'react-dropzone';

import { AutoSaveContext } from 'ui/components/AutoSave/AutoSaveContext';

// ...

export const useAutoSaveDropzone = (options) => {
  const { name, mutate, status } = useContext(AutoSaveContext);

  const disabled = status === 'loading';

  const { getRootProps, getInputProps } = useDropzone({
    ...options,

    multiple: false,
    disabled,

    async onDropAccepted(files) {
      const file = files[0];

      try {
        await mutate({
          [name]: file,
        });
      } catch (error) {
        // TBD: Report error via ErrorContext.
      }
    },
    onDropRejected() {
      console.log('rejected');
    },
  });

  return {
    getRootProps,
    getInputProps,

    disabled,
  };
};
