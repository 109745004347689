import { useState, useContext } from 'react';

import { AutoSaveContext } from '../ui/components/AutoSave/AutoSaveContext';

// ...

export const useAutoSaveSwitch = (initialValue) => {
  const [checked, setChecked] = useState(initialValue);

  const { name, mutate } = useContext(AutoSaveContext);

  // ...

  const onChange = async (event) => {
    const newChecked = event.target.checked;

    setChecked(newChecked);

    try {
      await mutate({
        [name]: newChecked,
      });
    } catch (error) {
      // TBD:
      //
      // Report error via ErrorContext.
      // Reset checked to previous value (tracked via a checkedRef).
    }
  };

  // ...

  return {
    value: checked,
    onChange,
  };
};
