import React from 'react';

import { Link } from 'react-router-dom';

import { List, ListItem, ListItemText, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// ...

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

// ...

export const ContentList = ({ pageData }) => {
  const styles = useStyles();

  const [product, contents] = pageData;

  return contents.length > 0 ? (
    <List
      classes={{
        root: styles.root,
      }}
    >
      {contents.map(content => (
        <ListItem
          key={content.id}
          button
          component={Link}
          to={content.id}
          state={{
            [product.id]: product.title,
            [content.id]: content.title,
          }}
        >
          <ListItemText primary={<Typography variant="caption">{content.title}</Typography>} />
        </ListItem>
      ))}
    </List>
  ) : null;
};
