import React from 'react';

import { Outlet } from 'react-router-dom';

import { ProductProvider } from './ProductContext';

// ...

export const ProductIndex = ({ type }) => {
  return (
    <ProductProvider type={type}>
      <Outlet />
    </ProductProvider>
  );
};
