import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import './index.css';

import App from './App';

// ...

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = window.location.origin;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

if (domain === undefined || clientId === undefined || redirectUri === undefined || audience === undefined) {
  throw new Error('Missing env vars');
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider domain={domain} clientId={clientId} redirectUri={redirectUri} audience={audience}>
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
