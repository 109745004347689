import React, { useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

// ...

export const useFormDialog = () => {
  const [open, setOpen] = useState(false);

  return [open, setOpen];
};

export const FormDialog = ({
  open,
  onClose,

  title,
  text,

  children,
  actions,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
