import { useDependency } from './useDependency';

// ...

export const useQueryFunction = () => {
  const entityService = useDependency('entityService');

  return {
    fetchProducts(vendorId, type) {
      return entityService.fetchProducts(vendorId, type);
    },

    fetchProduct(id) {
      return entityService.fetchProduct(id);
    },

    fetchContents(productId) {
      return entityService.fetchContents(productId);
    },

    fetchContent(id) {
      return entityService.fetchContent(id);
    },
  };
};
