import React from 'react';
import { OutlinedInput, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAutoSaveInput } from 'hooks/useAutoSaveInput';

// ...

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: '3rem',
  },

  error: {
    position: 'absolute',
    paddingTop: 5,
    fontSize: '3rem',
  },
}));

// ...

export const Input = ({ value: initialValue, validateFn, ...rest }) => {
  const styles = useStyles();

  let formatFn;

  if (rest.type === 'number') {
    formatFn = parseInt;
  }

  const { inputProps, validationError } = useAutoSaveInput(initialValue, validateFn, formatFn);

  return (
    <>
      <OutlinedInput
        className={styles.input}
        fullWidth
        {...rest}
        {...inputProps}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            event.target.blur();
          }
        }}
      />
      {validationError && (
        <Typography className={styles.error} variant="body2" color="error">
          {validationError.message}
        </Typography>
      )}
    </>
  );
};
