import React from 'react';

import { FormikHelpers, useFormik } from 'formik';

import { Button, InputBase, Typography } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';

import isEmail from 'validator/es/lib/isEmail';

// ...

export type EnterUsernameFormData = {
  username: string;
};

type EnterUsernameFormProps = {
  onSubmit: (data: EnterUsernameFormData, actions: FormikHelpers<EnterUsernameFormData>) => void;
  validate?: (values: EnterUsernameFormData) => object;
};

const validate = ({ username }: EnterUsernameFormData) => {
  const errors: any = {};

  const makeError = (errorMessage: string) => (
    <Typography variant="body2" color="error">
      {errorMessage}
    </Typography>
  );

  if (!username) {
    errors.username = makeError('email is required');
  } else if(!isEmail(username)) {
    errors.username = makeError('Enter valid email');
  }

  return errors;
};

// ...

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    position: 'relative',
  },

  input: {
    border: '1px solid',
    padding: theme.spacing(1),
    margin: `${theme.spacing(1)} auto`,
  },

  submit: {
    marginTop: theme.spacing(1),
  },
}));

// ...

export const EnterUsernameForm = ({ onSubmit }: EnterUsernameFormProps) => {
  const styles = useStyles();

  const initialValues: EnterUsernameFormData = {
    username: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <>
      <form className={styles.form} onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <InputBase
          classes={{
            root: styles.input,
          }}
          fullWidth
          id="username"
          name="username"
          placeholder="Email"
          onChange={formik.handleChange}
          value={formik.values.username}
        />
        {formik.errors.username}

        <Button
          className={styles.submit}
          disabled={formik.isSubmitting}
          type="submit"
          color="primary"
          disableElevation
          fullWidth
          size="large"
          variant="contained"
        >
          Send me a verification code
        </Button>
      </form>
    </>
  );
};
