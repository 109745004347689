import { useEffect } from 'react';
import axios from 'axios';

import AuthApi from './services/auth';

export const authAxios = (() => {
  const newAxios = axios.create({
    withCredentials: true,
  });

  newAxios.interceptors.request.use(
    async config => {
      config.headers.Authorization = `Bearer ${AuthApi.getAccessToken()}`;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  newAxios.interceptors.response.use(
    response => {
      return response;
    },
    function (error) {
      if (error.response.status === 403) {
        // what to do here?
        // localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        // can delete HTTPS cookie? no!

        // send to auth app
        // window.location.href = "/login?invalid_token";

        console.error('403: token invalid!');

        // try /refresh endpoint here first
        // do rest same as line 10
        // BUT, if /refresh returns OK, how to retry response?
      }
      return Promise.reject(error);
    },
  );

  return newAxios;
})();

export const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
