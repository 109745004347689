import React from 'react';

import { useAuth0 } from "@auth0/auth0-react";

import { Button, Menu, MenuItem, Typography as T } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { Link } from 'react-router-dom';

import { useHeaderExtension } from './useHeaderExtension';

// ...

const links = [
  {
    link: '',
    name: 'Search',
  },
  {
    link: 'audiobooks',
    name: 'Audiobooks',
  },
  {
    link: 'ebooks',
    name: 'Ebooks',
  },
  {
    link: 'podcasts',
    name: 'Podcasts',
  },
  {
    link: 'settings',
    name: 'Settings',
  },
];

const useStyles = makeStyles(theme => ({
  headerText: {
    fontSize: 18,
    fontWeight: 'normal',
    textTransform: 'none',
  },
  // TODO - not sure how to inherit. use NPM classnames or better alternative?
  menuItem: {
    fontSize: 18,
    fontWeight: 'normal',
    textTransform: 'none',
    color: '#FFF',
    textDecoration: 'none',
    marginRight: 10,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  rightMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export const HeaderExtension = () => {
  const styles = useStyles();
  const { logout } = useAuth0();


  const {
    menuTarget,
    setMenuTarget,
    navigate,
    vendors,
    vendor,

    username,
  } = useHeaderExtension();

  // ...

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftMenu}>
        {links.map(({ link, name }) => (
          <Link className={styles.menuItem} key={link} to={link}>
            {name}
          </Link>
        ))}
      </div>
      <div className={styles.rightMenu}>
        <Button color="inherit" onClick={event => setMenuTarget(event.currentTarget)} className={styles.headerText}>
          {vendor.name}
          <ExpandMoreIcon fontSize="small" />
        </Button>

        <T className={styles.headerText} variant="caption">
          {username}
        </T>

        <Menu
          anchorEl={menuTarget}
          getContentAnchorEl={null}
          // https://stackoverflow.com/a/52551100
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!menuTarget}
          onClose={() => setMenuTarget(null)}
          className={styles.headerText}
        >
          {vendors.map(v => (
            <MenuItem
              key={v.id}
              component="a"
              selected={v.id === vendor.id}
              onClick={() => {
                setMenuTarget(null);

                // redirect to the current vendor's home.
                navigate(`/${v.id}`);
              }}
              className={styles.headerText}
            >
              {v.name}
            </MenuItem>
          ))}
        </Menu>

        <Button color="inherit" onClick={() => logout({ returnTo: window.location.origin })}>
          <T className={styles.headerText} variant="caption">
            | Logout
          </T>
        </Button>
      </div>
    </div>
  );
};
