import { queryCache } from 'react-query';

import { getLayout } from '../../layout/ProductStateLayout';

import { ContentList } from './ContentList';

// ...

ContentList.pageProps = {
  getLayout,

  getPathData(locationState, data) {
    let pathData = null;

    if (locationState) {
      pathData = locationState;
    }

    if (data) {
      const product = data[0];

      pathData = { [product.id]: product.title };
    }

    return pathData;
  },
  getQuery({ params }, { fetchProduct, fetchContents }) {
    const doFetchProduct = async () => {
      const product = queryCache.getQueryData(['product', params.id]);
      if (product) {
        return product;
      }

      // Q: setQueryData for this product?
      return fetchProduct(params.id);
    };

    return [['contents', params.id], () => Promise.all([doFetchProduct(params.id), fetchContents(params.id)])];
  },
};

export { ContentList };
