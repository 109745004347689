import React from 'react';

import { IconButton, InputBase, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { Search as SearchIcon } from '@material-ui/icons';

// ...

const useStyles = makeStyles((theme) => ({
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },

  searchInput: {
    marginLeft: theme.spacing(1),
    fontSize: '3rem',
    flex: 1,
  },

  searchIcon: {
    padding: 10,
  },
}));

// ...

export const Search = ({ placeholder, disabled = false, onSearch }) => {
  const styles = useStyles();

  return (
    <Paper className={styles.searchRoot} elevation={0}>
      <InputBase
        className={styles.searchInput}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            const { value } = event.target;

            if (!value.length) {
              return;
            }

            onSearch(value);
          }
        }}
      />
      <IconButton className={styles.searchIcon}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
