import * as yup from 'yup';

// ...

const baseSchema = yup.string().required();

const validationMap = {
  title: baseSchema,

  description: baseSchema,
};

// ...

export const validate = (key, value) => {
  return validationMap[key].validateSync(value);
};
