import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';

// ...

export const useHeaderExtension = () => {
  const [menuTarget, setMenuTarget] = useState(null);
  const auth = useAuth();

  const navigate = useNavigate();

  return {
    menuTarget,
    setMenuTarget,
    navigate,
    logout: auth.logout,
    vendors: auth.user.vendors,
    vendor: auth.user.currentVendor,
    username: auth.user.email,
  };
};
