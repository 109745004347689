import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { useMutation } from 'hooks';
import { FormDialog, useFormDialog } from '../../../components/FormDialog';
import { ProductContext } from '../ProductContext';
import { useAuth } from '../../../../Contexts/AuthContext';

// ...

export const ToolbarExtension = ({ loadedProps }) => {
  const [createProduct] = useMutation().useCreateProduct();
  const auth = useAuth();

  // ...
  // Retrieve the current vendor context and product type.
  const { type } = useContext(ProductContext);

  // ...

  const [title, setTitle] = useState('');

  const handleChange = e => setTitle(e.target.value);

  const [open, setOpen] = useFormDialog();

  const handleSubmit = () => {
    createProduct(
      {
        type,
        vendorId: auth.user.currentVendor.id,
        title,
      },
      {
        onSuccess(created) {
          setOpen(false);

          navigate(`${created.id}`, {
            state: {
              [created.id]: title,
            },
          });
        },
      },
    );
  };

  const navigate = useNavigate();

  // ...

  return (
    <>
      <Button startIcon={<AddIcon />} onClick={() => setOpen(true)}>
        Product
      </Button>
      <FormDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Add Product"
        text="Enter a title for the new product."
        actions={
          <>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleSubmit}>oK</Button>
          </>
        }
      >
        <TextField autoFocus fullWidth value={title} onChange={handleChange} />
      </FormDialog>
    </>
  );
};
