import * as React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

// ...
// Custom Sesamy theme.

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },

    error: {
      main: '#FC3F1D',
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },

  spacing: factor => `${3 * factor}rem`, // e.g. (3 * 1)rem <=> 15px

  typography: {
    htmlFontSize: 5,

    fontFamily: `"Helvetica Neue", Helvetica, Arial, "Noto Sans", sans-serif`,

    h1: {
      fontSize: '20rem',
      fontWeight: 400,
      lineHeight: 1,
      letterSpacing: '-0.015em',
    },
    h2: {
      fontSize: '11rem',
      fontWeight: 400,
      lineHeight: 1.1,
      letterSpacing: '-0.015em',
    },
    h3: {
      fontSize: '7rem',
      fontWeight: 500,
      lineHeight: 1.15,
      letterSpacing: 0,
    },
    h4: {
      fontSize: '4rem',
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: 0,
    },
    h5: {
      fontSize: '3rem',
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: '4rem',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: 0,
    },
    subtitle2: {
      fontSize: '3rem',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: 0,
    },
    body1: {
      fontSize: '5rem',
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
    body2: {
      fontSize: '4rem',
      fontWeight: 400,
      lineHeight: 1.25,
      letterSpacing: '-0.015em',
    },
    caption: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.333,
      letterSpacing: 0,
    },
  },

  // ...
  // Custom theme variables.

  custom: {
    headerHeight: 50,
  },

  shadows: ['none'],
});

// ...

const SesamyThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

// ...

export default SesamyThemeProvider;
