const ToolbarExtension = () => {
  return '';
};

ToolbarExtension.getProps = (_, pageData) => {
  let loadedProps;
  
  if (pageData) {
    const { id} = pageData[1]; 

    loadedProps = {
      id,      
    };
  }

  return { loadedProps };
};

export { ToolbarExtension };
