import React, { useState, useEffect } from 'react';
import { useMount } from 'react-use';
import { useLocation } from 'react-router-dom';

import { authAxios } from '../utils';
import AuthApi from '../services/auth';
import Layout from '../Components/Layout';
import Spinner from '../Components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';


export const AuthContext = React.createContext(null);


export const AuthProvider = props => {
  const {getAccessTokenSilently} = useAuth0();

  function useAuth0User(){
    const {user} = useAuth0();
    return user;
  }

  async function getToken() {
    try {
      const token = await getAccessTokenSilently();
      return token

    } catch (error) {
      console.log('error', error);
    }
  }

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [user, setUser] = useState({
    id: null,
    vendors: [],
    currentVendor: {},
  });
  const location = useLocation();
  const vendorId = location.pathname.substring(1, 37);

  const auth0User = useAuth0User()

  const updateUser = (name, value) =>
    setUser(prevState => ({
      ...prevState,
      [name]: value,
    }));

  async function getVendors(vendorId) {
    const token = await getToken()
    // changed to { data: {data} } - nested destructuring as response format changed to data.data
    const { data: {data} } = await authAxios.get(`${process.env.REACT_APP_API}/publisher/vendors?limit=500&sort=name`, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    updateUser('vendors', data);
    if (data.length !== 0) {
      const currentVendor = data.find(vendor => vendor.id === vendorId);
      updateUser('currentVendor', currentVendor || data[0]);
    }
  }

  useEffect(() => {
    if (user.vendors.length && vendorId !== user.currentVendor?.id) {
      const currentVendor = user.vendors.find(vendor => vendor.id === vendorId);
      updateUser('currentVendor', currentVendor || user.vendors[0]);
    }
  });

  useMount(async () => {
    try {
      // we have no refresh token here
      // const { data } = await axios.get(`${process.env.REACT_APP_API}/refresh`, {
      //   withCredentials: true,
      // });
      // const { access_token, user_id, user_email } = data;
      const token = await getToken()
      AuthApi.setAccessToken(token);
      AuthApi.setUserId(auth0User.user_id);
      updateUser('email', auth0User.email);
      updateUser('id', auth0User.user_id);
      await getVendors(vendorId);
      setIsLoggedIn(true);
    } catch (err) {
      console.log(err.message);
      // login();
    }
  });

  if (!isLoggedIn) {
    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }
  return <AuthContext.Provider value={{ user, updateUser, getVendors }} {...props} />;
};

export const useAuth = () => React.useContext(AuthContext);
