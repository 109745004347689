import { queryCache } from 'react-query';

import { getLayout } from '../../layout/ProductStateLayout';

import { ContentEdit } from './ContentEdit';

import { ToolbarExtension } from './ToolbarExtension';

// ...

ContentEdit.pageProps = {
  getLayout,

  getPathData(locationState, data) {
    let pathData = null;

    if (locationState) {
      pathData = locationState;
    }

    if (data) {
      const [product, content] = data;

      pathData = {
        [product.id]: product.title,
        [content.id]: content.title,
      };
    }

    return pathData;
  },

  getQuery({ params }, { fetchProduct, fetchContent }) {
    const doFetchProduct = async () => {
      const product = queryCache.getQueryData(['product', params.id]);
      if (product) {
        return product;
      }

      // Q: setQueryData for this product?
      return fetchProduct(params.id);
    };

    return [
      ['content', params.contentId],
      () => Promise.all([doFetchProduct(params.id), fetchContent(params.contentId)])
    ];
  },
  
  ToolbarExtension
};

export { ContentEdit };
