import { useState } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListSubheader, Toolbar, Typography } from '@material-ui/core';
import { useMutation } from 'hooks';
import { useAuth } from '../../Contexts/AuthContext';
import { Search as SearchBar } from '../components/Search';

// ...

const makeProductLink = ({ type, id }) => {
  switch (type) {
    case 'audiobook':
      return `audiobooks/${id}`;

    case 'ebook':
      return `ebooks/${id}`;

    default:
      throw new Error('Unknown product type: ' + type);
  }
};

const makeContentLink = entity => {
  return `${makeProductLink(entity)}/contents/${entity.content_id}`;
};

// ...

export const Search = () => {
  const [search, status] = useMutation().useSearch();
  const auth = useAuth();

  const [searchResults, setSearchResults] = useState(null);

  const handleSearch = async query => {
    const { data } = await search({
      query,
      vendorId: auth.user?.currentVendor?.id,
    });
    setSearchResults(data);
  };

  const renderSearchResults = (entities, href) => {
    return (
      entities &&
      entities.length > 0 &&
      entities.map(entity => (
        <ListItem key={entity.id} button component={Link} to={href(entity)}>
          <ListItemText primary={<Typography variant="caption">{entity.title}</Typography>} />
        </ListItem>
      ))
    );
  };

  return (
    <>
      <Toolbar disableGutters>
        <SearchBar placeholder="Search content" disabled={status === 'loading'} onSearch={handleSearch} />
      </Toolbar>
      {searchResults ? (
        <List>
          <ListSubheader disableSticky>Products</ListSubheader>
          {renderSearchResults(searchResults.products, makeProductLink)}
          <ListSubheader disableSticky>Content</ListSubheader>
          {renderSearchResults(searchResults.content, makeContentLink)}
        </List>
      ) : null}
    </>
  );
};
