import React from 'react';

import { AutoSaveContext } from './AutoSaveContext';

import { Status } from './AutoSaveStatus';

// ...

const AutoSave = ({ name, mutationHook: useMutation, children }) => {
  const [mutate, status] = useMutation();

  return (
    <AutoSaveContext.Provider
      value={{
        name,
        mutate,
        status,
      }}
    >
      {children}
    </AutoSaveContext.Provider>
  );
};

AutoSave.Status = Status;

// ...

export { AutoSave };
