import React from 'react';

import { Link, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { useAutoSaveDropzone } from 'hooks/useAutoSaveDropzone';

// ...

const useStyles = makeStyles(() => ({
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',
    margin: 'auto',
    width: '100%',
    height: 150,

    borderWidth: 1,
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: 5,

    opacity: props => (props.disabled ? 0.5 : 'initial'),
  },

  content: {
    padding: 8,
    display: 'block',
    width: 'auto',
    height: '100%',
  },
}));

// ...

const typeMap = {
  image(src, styles) {
    return <img className={styles.content} src={src} alt="" />;
  },

  audio(src) {
    return (
      <audio key={src} controls>
        <source src={src} type="audio/mpeg" />
      </audio>
    );
  },

  ebook(src) {
    const filename = src.split('/').pop().split('?')[0];

    return (
      <Link href={src} underline="none" onClick={e => e.stopPropagation()}>
        {filename}
      </Link>
    );
  },
};

// ...

export const Upload = ({ value: src, type, ...rest }) => {
  const {
    getRootProps,
    getInputProps,

    disabled,
  } = useAutoSaveDropzone(rest);

  const styles = useStyles({
    disabled,
  });

  // ...

  const dropzoneContent = src ? typeMap[type](src, styles) : <Typography variant="caption">Upload</Typography>;

  // ...

  return (
    <div {...getRootProps({ className: styles.dropzone })}>
      <input {...getInputProps()} />
      {dropzoneContent}
    </div>
  );
};
