import { Routes, Route } from 'react-router-dom';

import {
  Root,
  RootRedirect,
  Search,
  Settings,
  ContentEdit,
  ContentList,
  ProductIndex,
  ProductEdit,
  ProductList,
} from 'ui/pages';

import { Page } from 'ui/pages/layout/Page';

// ...

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RootRedirect />} />

      <Route path=":vendorId" element={<Root />}>
        <Route path="" element={<Search />} />

        <Route path="audiobooks" element={<ProductIndex type="audiobook" />}>
          <Route path="" element={<Page component={ProductList} />} />

          <Route path=":id" element={<Page component={ProductEdit} />} />
          <Route path=":id/contents" element={<Page component={ContentList} />} />
          <Route path=":id/contents/:contentId" element={<Page component={ContentEdit} />} />
        </Route>

        <Route path="ebooks" element={<ProductIndex type="ebook" />}>
          <Route path="" element={<Page component={ProductList} />} />

          <Route path=":id" element={<Page component={ProductEdit} />} />
          <Route path=":id/contents" element={<Page component={ContentList} />} />
          <Route path=":id/contents/:contentId" element={<Page component={ContentEdit} />} />
        </Route>

        <Route path="podcasts" element={<ProductIndex type="podcast" />}>
          <Route path="" element={<Page component={ProductList} />} />

          <Route path=":id" element={<Page component={ProductEdit} />} />
          <Route path=":id/contents" element={<Page component={ContentList} />} />
          <Route path=":id/contents/:contentId" element={<Page component={ContentEdit} />} />
        </Route>

        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};
