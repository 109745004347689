import React from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Breadcrumbs as MuiBreadCrumbs } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import { Link } from './Link';

// ...

const useBreadcrumbs = pathData => {
  const { pathname } = useLocation();

  let pathComponents = pathname.split('/').filter(pathComponent => pathComponent);

  // ...
  // TBD: The parent path, and the path components to utilize, should be derived from a function passed to this hook.
  const parentPath = `${pathComponents[0]}/${pathComponents[1]}`;

  pathComponents = pathComponents.slice(2);

  // build breadcrumb paths from the current location.
  //
  // E.g., a location of /[vendorId]/audiobooks/[id]/episodes yields the following:
  //
  // /[vendorId]/audiobooks/[id]
  // /[vendorId]/audiobooks/[id]/episodes
  //
  // The location state in this case must contain a path alias for [id].

  let pathState = {};

  return pathComponents.map((pathComponent, i) => {
    const to = `/${parentPath}/${pathComponents.slice(0, i + 1).join('/')}`;

    if (pathData[pathComponent]) {
      pathState = {
        ...pathState,
        [pathComponent]: pathData[pathComponent],
      };
    }

    return {
      to,
      state: {
        ...pathState,
      },
      label: pathData[pathComponent] ? pathData[pathComponent] : pathComponent,
    };
  });
};

// ...

const useStyles = makeStyles(() => ({
  link: {
    fontSize: '3rem',
    fontWeight: 400,
    lineHeight: 0,
    letterSpacing: '-0.015em',
  },

  separator: {
    marginTop: 8,
    marginLeft: 6,
    marginRight: 6,
  },
}));

// ...

export const Breadcrumbs = ({ pathData = {} }) => {
  const styles = useStyles();

  const classNames = clsx(styles.link, styles.separator);

  const breadcrumbs = useBreadcrumbs(pathData);

  return (
    <MuiBreadCrumbs
      classes={{
        separator: classNames,
      }}
      separator={<span>/</span>}
    >
      {breadcrumbs.map((breadcrumb) => (
        <Link
          key={breadcrumb.to}
          className={styles.link}
          component={RouterLink}
          to={breadcrumb.to}
          state={breadcrumb.state}
        >
          {breadcrumb.label}
        </Link>
      ))}
    </MuiBreadCrumbs>
  );
};
