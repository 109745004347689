import React from 'react';

import { Outlet } from 'react-router-dom';

import { Container, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Header } from './Header';
import { HeaderExtension } from './HeaderExtension';

// ...

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },

  listItem: {
    paddingLeft: theme.spacing(3),
  },
}));

// ...

// ...

export const Root = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Header>
        <HeaderExtension />
      </Header>
      <Toolbar />
      <main>
        <Container>
          <Outlet />
        </Container>
      </main>
    </div>
  );
};
