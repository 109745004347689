import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryFunction } from 'hooks';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ProductContext } from '../product/ProductContext';
import { ProductToolbar } from './ProductToolbar';
import { useAuth } from '../../../Contexts/AuthContext';

// ...

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },

  breadcrumbs: {
    flexGrow: 1,
  },

  extension: {
    marginLeft: 'auto',
    display: 'flex',
  },
}));

// ...

// ...
// Hook for loading data required for rendering this layout.
const useLoader = (getPathData, getQuery) => {
  const auth = useAuth();

  const { data } = useQuery(
    ...getQuery(
      {
        vendorId: auth.user.currentVendor.id,
        type: useContext(ProductContext).type,
        params: useParams(),
      },
      useQueryFunction(),
    ),
  );

  // ...
  // getPathData may return the following values (as determined by a specific PageComponent):
  //
  // undefined: No path data is available.
  // null:      Path data is being loaded.
  // {}:        Path data has been loaded.
  let pathData = getPathData(useLocation().state, data);

  // ...

  return {
    pathData,
    pageData: data,
  };
};

// ...

const ProductLayout = ({ component: PageComponent }) => {
  const styles = useStyles();

  const { getPathData, getQuery, ToolbarExtension } = PageComponent.pageProps;

  const { pathData, pageData } = useLoader(getPathData, getQuery);

  // ...
  if (pathData === null) {
    return null;
  }

  return (
    <>
      <ProductToolbar>
        <div className={styles.toolbar}>
          {pathData !== undefined ? (
            <div className={styles.breadcrumbs}>
              <Breadcrumbs pathData={pathData} />
            </div>
          ) : null}
          <div className={styles.extension}>
            {ToolbarExtension ? (
              <ToolbarExtension
                {...(ToolbarExtension.getProps ? ToolbarExtension.getProps(pathData, pageData) : null)}
              />
            ) : null}
          </div>
        </div>
      </ProductToolbar>
      {pageData ? <PageComponent pageData={pageData} /> : <p>Sesamy loading content...</p>}
    </>
  );
};

export const getLayout = PageComponent => {
  return <ProductLayout component={PageComponent} />;
};
