import React, { useState } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Button, ButtonGroup, CircularProgress, TextField } from '@material-ui/core';

import { Add as AddIcon, List as ListIcon } from '@material-ui/icons';

import { useMutation } from 'hooks';

import { FormDialog, useFormDialog } from '../../../components/FormDialog';

// ...

const ToolbarExtension = ({ pathData, loadedProps }) => {
  const { usePublishProduct, useCreateContent } = useMutation();

  const [publishProduct] = usePublishProduct();
  const [createContent] = useCreateContent();
  // ...

  const [publishing, setPublishing] = useState(false);
  const [title, setTitle] = useState('');

  // ...

  const navigate = useNavigate();

  const [open, setOpen] = useFormDialog();

  const handleChange = e => setTitle(e.target.value);
  const handleSubmit = () => {
    createContent(
      {
        title,
        productId: loadedProps.id,
      },
      {
        onSuccess(created) {
          setOpen(false);

          navigate(`contents/${created.id}`, {
            state: {
              ...pathData,

              [created.id]: title,
            },
          });
        },
      },
    );
  };

  // ...
  // Extension elements available upon loadedProps.
  let btnPublishProduct = null;
  let btnCreateContent_disabled = true;

  if (loadedProps) {
    const { id, dirty } = loadedProps;

    btnPublishProduct = (
      <Button
        variant="contained"
        disableElevation
        // button is disabled if publishing is currently in progress or if product is not dirty.
        disabled={publishing || !dirty}
        onClick={async () => {
          setPublishing(true);

          try {
            await publishProduct({ id });
          } catch (error) {
            // TBD: Handle error.
          } finally {
            setPublishing(false);
          }
        }}
      >
        {publishing ? <CircularProgress size="4rem" /> : 'Publish'}
      </Button>
    );

    btnCreateContent_disabled = false;
  }

  // ...

  return (
    <>
      {btnPublishProduct}
      <ButtonGroup>
        <Button disabled={btnCreateContent_disabled} startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          Content
        </Button>
        <Button component={RouterLink} startIcon={<ListIcon />} to="contents" state={pathData}>
          Contents
        </Button>
      </ButtonGroup>
      <FormDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Add Content"
        text="Enter a title for the new content."
        actions={
          <>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleSubmit}>OK</Button>
          </>
        }
      >
        <TextField autoFocus fullWidth value={title} onChange={handleChange} />
      </FormDialog>
    </>
  );
};

ToolbarExtension.getProps = (pathData, product) => ({
  pathData,
  loadedProps: product && {
    id: product.id,
    dirty: product.dirty,
  },
});

export { ToolbarExtension };
