import { useState } from 'react';

import { Button, CircularProgress, OutlinedInput, Paper, Snackbar, Typography as T } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useMutation } from 'hooks';
import { useAuth } from '../../Contexts/AuthContext';

// ...

const useStyles = makeStyles(theme => ({
  offset: {
    minHeight: theme.spacing(1),
  },

  paper: {
    margin: `${theme.spacing(1)} 0`,
    padding: theme.spacing(2),
    width: '100%',

    display: 'grid',
  },

  paperElement: {
    marginTop: theme.spacing(1),
  },

  invite: {
    display: 'flex',
    alignItems: 'center',
  },

  inviteInputWrapper: {
    display: 'flex',
  },

  inviteInput: {
    width: 400,
    fontSize: '3rem',
  },

  inviteSend: {
    marginLeft: theme.spacing(1),
  },

  inviteStatus: {
    marginLeft: 'auto',
  },
}));

// ...

export const Settings = () => {
  const styles = useStyles();
  const auth = useAuth();

  // ...
  // Invite mutation.
  const { useInvite } = useMutation();
  const [mutate, status] = useInvite();

  // ...
  // Snackbar open / closed state.
  const [alertStatus, setAlertStatus] = useState({
    open: false,
  });

  const handleAlertClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertStatus(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  // ...
  // Formik configuration.
  const formik = useFormik({
    initialValues: {
      email: '',
    },

    async onSubmit({ email }, { setSubmitting }) {
      try {
        await mutate({
          vendor: auth.user.currentVendor.id,
          email,
        });

        setAlertStatus({
          open: true,
          severity: 'success',
          message: 'User was successfully added to your organisation',
        });
      } catch (error) {
        setAlertStatus({
          open: true,
          severity: 'error',
          message: 'User does not exist or already belongs to your organisation',
        });
      } finally {
        setSubmitting(false);
      }
    },

    validate(values) {
      const errors = {};

      if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = true;
      }

      return errors;
    },

    validateOnBlur: false,
    validateOnChange: false,
  });

  // ...

  return (
    <>
      <div className={styles.offset} />

      <T variant="h4">Users</T>
      <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
        <Paper className={styles.paper} variant="outlined" square>
          <T variant="body2">Invite users by email to join your organisation</T>
          <div className={clsx(styles.paperElement, styles.invite)}>
            <div className={styles.inviteInputWrapper}>
              <OutlinedInput
                id="email"
                name="email"
                type="email"
                className={styles.inviteInput}
                placeholder="User email address"
                onChange={formik.handleChange}
                error={formik.errors.email}
              />
              <Button className={styles.inviteSend} variant="outlined" type="submit" disabled={formik.isSubmitting}>
                Send Invite
              </Button>
            </div>
            {status === 'loading' ? <CircularProgress className={styles.inviteStatus} /> : null}
          </div>
        </Paper>
      </form>

      <Snackbar open={alertStatus.open} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alertStatus.severity} variant="filled" icon={false}>
          {alertStatus.message}
        </Alert>
      </Snackbar>
    </>
  );
};
