import React from 'react';

import { Link } from 'react-router-dom';

import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

// ...

const useStyles = makeStyles({
  imageWrapper: {
    backgroundColor: '#f4f4f4',
    padding: '10% 20%',
  },

  media: {
    height: 0,
    paddingBottom: '100%',
  },
});

// ...

export const ProductList = ({ pageData: products }) => {
  // return '<div>asdf</div>';

  const styles = useStyles();

  return (
    <Grid container spacing={1}>
      {products.map(({ id, title, image }) => (
        <Grid key={id} item xs={6} sm={4} md={3}>
          <Card className={styles.card}>
            <CardActionArea
              component={Link}
              to={id}
              state={{
                [id]: title,
              }}
            >
              <div className={styles.imageWrapper}>
                <CardMedia className={styles.media} image={image} />
              </div>
              <CardContent>
                <Typography variant="caption">{title}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
