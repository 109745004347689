import React from 'react';

// ...

export const ProductContext = React.createContext();

export const ProductProvider = ({ type, children }) => {
  const value = {
    type,
  };

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};
