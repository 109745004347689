import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

// ...

export const Link = ({ to, children, ...rest }) => {
  return (
    <MuiLink component={RouterLink} to={to} {...rest}>
      {children}
    </MuiLink>
  );
};
