import { getLayout } from '../../layout/ProductLayout';

import { ProductList } from './ProductList';
import { ToolbarExtension } from './ToolbarExtension';

// ...

ProductList.pageProps = {
  getLayout,

  getPathData() {
    return undefined;
  },
  
  getQuery({ vendorId, type }, { fetchProducts }) {
    return [['products', vendorId, { type }], () => fetchProducts(vendorId, type)]
  },

  ToolbarExtension,
};

export { ProductList };