import { getLayout } from '../../layout/ProductStateLayout';

import { ProductEdit } from './ProductEdit';
import { ToolbarExtension } from './ToolbarExtension';

// ...

ProductEdit.pageProps = {
  getLayout,

  getPathData(locationState, data) {
    let pathData = null;

    if (locationState) {
      pathData = locationState;
    }

    if (data) {
      pathData = { [data.id]: data.title };
    }

    return pathData;
  },
  
  getQuery({ params }, { fetchProduct }) {
    return [['product', params.id], () => fetchProduct(params.id)];
  },

  ToolbarExtension,
};

export { ProductEdit };
