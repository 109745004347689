import React from 'react';
import { useScript } from '../utils';

const Spinner = () => {
  useScript('https://cdn.sesamy.com/logo.min.js');

  return (
    <>
      <div id="lottie"></div>
    </>
  );
};

export default Spinner;
