import React from 'react';

import { getLayout as getProductLayout } from './ProductLayout';

import { ProductStateProvider } from './ProductStateContext';

// ...

export const getLayout = PageComponent => {
  return <ProductStateProvider>{getProductLayout(PageComponent)}</ProductStateProvider>;
}
