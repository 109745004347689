import { useState, useContext, useRef } from 'react';

import { AutoSaveContext } from '../ui/components/AutoSave/AutoSaveContext';

// ...

export const useAutoSaveInput = (initialValue, validateFn, formatFn) => {
  const [value, setValue] = useState(initialValue);

  const [validationError, setValidationError] = useState(null);

  const { name, mutate, status } = useContext(AutoSaveContext);
  
  const valueRef = useRef(initialValue);

  // ...

  const onChange = (event) => {
    const changedValue = formatFn ? formatFn(event.target.value) : event.target.value;
    setValue(changedValue);

    validationError && setValidationError(null);
  };

  const onBlur = async () => {
    if (value !== valueRef.current) {
      try {
        // only validate if a validation function is defined.
        validateFn && validateFn(name, value);
      } catch (error) {
        return setValidationError(error);
      }

      try {
        await mutate(
          {
            [name]: value,
          }
        );

        valueRef.current = value;
      } catch (error) {
        // TBD: Report error via ErrorContext.
      }
    }
  };

  // ...

  return {
    inputProps: {
      value,
      disabled: status === 'loading',

      onChange,
      onBlur,
    },
    validationError,
  };
};
