import React from 'react';

import { formFields } from './formConfig';

// ...

export const FormContext = React.createContext();

export const FormProvider = ({ name, context = {}, children }) => {
  const fields = formFields(name);

  return <FormContext.Provider value={context}>{children(fields)}</FormContext.Provider>;
};
