import React from 'react';

const Layout = props => {
  return (
    <div
      flexDirection="column"
      alignItems="center"
      width="100vw"
      minHeight="100vh"
      bg="orange.400"
      color="black"
      pb="2rem"
      {...props}
    ></div>
  );
};

export default Layout;
