import React from 'react';

import { Input } from './Input';
import { Switch } from './Switch';
import { Upload } from './Upload';
import { validate } from './validate';

// ...

const baseForms = {
  'product.edit': [
    {
      name: 'title',
      label: 'Title',
      Component: props => <Input validateFn={validate} {...props} />,
    },
    {
      name: 'description',
      label: 'Description',
      Component: props => <Input validateFn={validate} {...props} multiline rows={5} />,
    },
    {
      name: 'url',
      label: 'Url',
      Component: props => <Input {...props} />,
    },
    {
      name: 'language',
      label: 'Language',
      Component: props => <Input {...props} />,
    },
    {
      name: 'publishedAt',
      label: 'Publishing Date',
      Component: props => <Input type="date" {...props} />,
    },
    {
      name: 'isbn',
      label: 'ISBN',
      Component: props => <Input {...props} />,
    },
    {
      name: 'authors',
      label: 'Authors',
      Component: props => <Input {...props} />,
    },
    {
      name: 'translators',
      label: 'Translators',
      Component: props => <Input {...props} />,
    },
    {
      name: 'series',
      label: 'Series',
      Component: props => <Input {...props} />,
    },
    {
      name: 'image',
      label: 'Image',
      Component: ({ value }) => <Upload value={value} type="image" accept={['image/jpeg', 'image/png']} />,
    },
    {
      name: 'price',
      label: 'Price',
      Component: props => <Input type="number" {...props} />,
    },
  ],

  'content.edit': [
    {
      name: 'title',
      label: 'Title',
      Component: props => <Input validateFn={validate} {...props} />,
    },
    {
      name: 'description',
      label: 'Description',
      Component: props => <Input validateFn={validate} {...props} multiline rows={5} />,
    },
    {
      name: 'public',
      label: 'Public',
      Component: props => <Switch {...props} />,
    },
    {
      name: 'sample',
      label: 'Sample',
      Component: props => <Switch {...props} />,
    },
    {
      name: 'image',
      label: 'Image',
      Component: ({ value }) => <Upload value={value} type="image" accept={['image/jpeg', 'image/png']} />,
    },
  ],
};

const forms = {
  'audiobook.edit': [
    ...baseForms['product.edit'],
    {
      name: 'reciters',
      label: 'Reciters',
      Component: props => <Input {...props} />,
    },
  ],

  'audiobook.content.edit': [
    ...baseForms['content.edit'],
    {
      name: 'mediaUrl',
      label: 'Audio',
      Component: ({ value }) => <Upload value={value} type="audio" accept={['audio/mpeg']} />,
    },
    {
      name: 'order',
      label: 'Order',
      Component: props => <Input type="number" {...props} />,
    },
    {
      name: 'mediaUrl',
      label: 'File',
      Component: ({ value }) => <Upload value={value} type="ebook" accept={['.mp4']} />,
    },
  ],

  'ebook.edit': [...baseForms['product.edit']],

  'ebook.content.edit': [
    ...baseForms['content.edit'],

    {
      name: 'mediaUrl',
      label: 'File',
      Component: ({ value }) => (
        <Upload value={value} type="ebook" accept={['application/pdf', 'application/epub+zip', '.mobi']} />
      ),
    },
  ],

  'podcast.edit': [...baseForms['product.edit']],

  'podcast.content.edit': [
    ...baseForms['content.edit'],
    {
      name: 'mediaUrl',
      label: 'Feed url',
      Component: props => <Input {...props} />,
    },
    {
      name: 'mediaUrl',
      label: 'Audio',
      Component: ({ value }) => <Upload value={value} type="audio" accept={['audio/mpeg']} />,
    },
    {
      name: 'lockedFrom',
      label: 'Locked From',
      Component: props => <Input {...props} />,
    },
    {
      name: 'lockedTo',
      label: 'Locked To',
      Component: props => <Input {...props} />,
    },
    {
      name: 'itemLimit',
      label: 'Item Limit',
      Component: props => <Input type="number" {...props} />,
    },
  ],
};

// ...

export const formFields = formName => forms[formName];
