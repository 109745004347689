import React from 'react';

import { Switch as MuiSwitch } from '@material-ui/core';

import { useAutoSaveSwitch } from 'hooks';

// ...

export const Switch = ({ value: initialValue }) => {
  const { value, onChange } = useAutoSaveSwitch(initialValue);

  return <MuiSwitch checked={value} onChange={onChange} />;
};
