import React from 'react';

import { Toolbar } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

// ...

const useStyles = makeStyles(theme => ({
  toolbar: {
    position: 'sticky',
    zIndex: 1,
    backgroundColor: theme.palette.background.default,

    ...theme.mixins.toolbar,
  },

  toolbarTop: {
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
  },
}));

// ...

export const ProductToolbar = ({ children }) => {
  const styles = useStyles();

  return (
    <Toolbar disableGutters className={clsx(styles.toolbar, styles.toolbarTop)}>
      {children}
    </Toolbar>
  );
};
