import React, { useState, useEffect, useContext } from 'react';

import { CircularProgress, Fade } from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';

import { AutoSaveContext } from './AutoSaveContext';

// ...

const Fader = ({ timeout, children }) => {
  const [enterTransition, setEnterTransition] = useState(true);

  useEffect(() => {
    // guard: this component may be unmounted before the transition completes.
    let abort = false;

    setTimeout(() => {
      !abort && setEnterTransition(false);
    }, 3000);

    return () => (abort = true);
  }, []);

  return (
    <Fade in={enterTransition} timeout={timeout}>
      {children}
    </Fade>
  );
};

// ...

export const Status = () => {
  const { status } = useContext(AutoSaveContext);

  // ...
  // Determine status icon to render.
  let icon = null;

  if (status === 'loading') {
    icon = <CircularProgress size={30} />;
  } else if (status === 'success') {
    icon = (
      <Fader timeout={500}>
        <DoneIcon style={{ color: 'green' }} />
      </Fader>
    );
  }

  return icon;
};
