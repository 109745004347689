import React from 'react';

import { EntityService } from './services';

// ...

const entityService = new EntityService(process.env.REACT_APP_API);

const dependencies = {
  entityService: entityService,
};

// ...

export const DependencyContext = React.createContext();

export const DependencyProvider = ({ children }) => {
  return <DependencyContext.Provider value={dependencies}>{children}</DependencyContext.Provider>;
};
