import React from 'react';

import { Grid, InputLabel, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { useMutation } from 'hooks';

import { AutoSave } from 'ui/components';

import { FormProvider } from '../form/FormProvider';

// ...

const useStyles = makeStyles(() => ({
  paper: {
    padding: '30px 0',
  },

  label: {
    fontSize: '4rem',
  },

  status: {
    textAlign: 'center',
  },
}));

// ...

export const ProductEdit = ({ pageData: product }) => {
  const styles = useStyles();

  const { useUpdateProduct } = useMutation();

  return (
    <Paper className={styles.paper} elevation={0} variant="outlined" square>
      <Grid container spacing={3} justify="center">
        <FormProvider name={`${product.type}.edit`}>
          {fields =>
            fields.map(field => (
              <Grid key={field.name} container item xs={10} alignItems="center">
                <AutoSave name={field.name} mutationHook={useUpdateProduct.bind(null, product.id)}>
                  <Grid item xs={4}>
                    <InputLabel className={styles.label}>{field.label}</InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <field.Component value={product[field.name]} />
                  </Grid>
                  <Grid item xs={2} className={styles.status}>
                    <AutoSave.Status />
                  </Grid>
                </AutoSave>
              </Grid>
            ))
          }
        </FormProvider>
      </Grid>
    </Paper>
  );
};
