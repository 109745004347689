import React, { useState } from 'react';

// ...

export const ProductStateContext = React.createContext();

export const ProductStateProvider = ({ children }) => {
  const [productState, setProductState] = useState({
    tagQuery: ''
  });

  const setState = newState => setProductState(prevState => ({
    ...prevState,
    ...newState
  }))

  const value = {
    state: productState,
    setState
  };

  return (
    <ProductStateContext.Provider value={value}>
      {children}
    </ProductStateContext.Provider>
  )
}