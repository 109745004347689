class AuthApi {
  accessToken;
  userId;

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  getAccessToken() {
    return this.accessToken;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  getUserId() {
    return this.userId;
  }

  setUserEmail(userEmail) {
    this.userEmail = userEmail;
  }

  getUserEmail() {
    return this.userEmail;
  }

  logout() {
    this.accessToken = null;
  }
}

export default new AuthApi();
