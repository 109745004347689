import React, {useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { DependencyProvider } from './DependencyContext';
import SesamyThemeProvider from './theme';
import { AppRoutes } from './AppRoutes';
import { AuthProvider } from './Contexts/AuthContext';
import { useAuth0, withAuth0 } from '@auth0/auth0-react';
import Layout from './Components/Layout';
import Spinner from './Components/Spinner';

// ...

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

// ...

const App = () => {
  const [auth, setAuth] = useState({
    token: null,
    error: null,
  });

  const {getAccessTokenSilently, isLoading, isAuthenticated, loginWithRedirect} = useAuth0();

  /**
   * This method retrieves the auth0 token for the user and should be called only after load,
   * authentication and when it doesn't exist yet. Otherwise, it will give an error or even
   * create an infinite loop of renderings.
   */
  async function getToken() {
    try {
      const token = await getAccessTokenSilently();
      setAuth({ token });
    } catch (error) {
      setAuth({ error });
    }
  }

  if (auth.error) {
    return <div>{auth.error}</div>;
  }

  if (isLoading) {
    return (
      <Layout py="0" bg="#fab413">
        <Spinner />
      </Layout>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}`,
      },
    });

    return (
      <Layout>
        <Spinner />
      </Layout>
    );
  }

  if (!auth.token) {
    getToken();
  }

  return (
    <Router>
      <ReactQueryConfigProvider config={queryConfig}>
        <SesamyThemeProvider>
          <AuthProvider>
            <DependencyProvider>
              <AppRoutes />
            </DependencyProvider>
          </AuthProvider>
        </SesamyThemeProvider>
      </ReactQueryConfigProvider>
    </Router>
  );
};

export default withAuth0(App);
