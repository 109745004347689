import React from 'react';

import { Navigate } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';

// ...

export const RootRedirect = () => {
  const auth = useAuth();

  return <Navigate to={auth.user.currentVendor.id} />;
};
