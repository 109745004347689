import React from 'react';

import { AppBar, SvgIcon, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as SesamyLogo } from 'assets/sesamy-logo_white.svg';

// ...

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  logo: {
    height: '100%',
    width: 'auto',
  },

  extension: {
    marginLeft: 10,
    width: '100%'
  },

  toolbar: {
    minHeight: 50,
  }
}));

// ...

const Header = ({ children }) => {
  const styles = useStyles();

  return (
    <AppBar position="fixed" className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        <SvgIcon component={SesamyLogo} className={styles.logo} viewBox="0 0 36 30" />
        <div className={styles.extension}>{children}</div>
      </Toolbar>
    </AppBar>
  );
};

export { Header };
