import axios from 'axios';
import AuthApi from './auth';

// ...

export class EntityService {
  constructor(apiBaseUri) {
    // ...
    // Create an Axios instance specific to this service.
    this.axios = axios.create({
      baseURL: `${apiBaseUri}/publisher`,
      withCredentials: true,
    });

    this.apiBaseUri = apiBaseUri;

    this.axios.interceptors.request.use(
      async config => {
        config.headers.Authorization = `Bearer ${AuthApi.getAccessToken()}`;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );
  }

  // ...

  async fetch(url) {
    const res = await this.axios(url);
    const {
      data: { data },
    } = res;
    return data;
  }

  async create(url, dataToSend) {
    const { data } = await this.axios.post(url, [dataToSend]);

    return data;
  }

  async update(url, dataToUpdate) {
    const res = await this.axios.patch(url, dataToUpdate);
    const {
      data: { data },
    } = res;
    return data;
  }

  delete(url) {
    return this.axios.delete(url);
  }

  // ...
  // Products.

  async fetchProducts(vendorId, type) {
    const data = await this.fetch(`/products?vendorId=${vendorId}&type=${type}`);
    return data;
  }

  async fetchProduct(id) {
    const data = await this.fetch(`/products?id=${id}`);

    return data[0];
  }

  async createProduct(productData) {
    const data = await this.create('/products', productData);

    return data[0];
  }

  updateProduct(id, data) {
    return this.update(`/products/${id}`, data);
  }

  // ...
  // Content.

  fetchContents(productId) {
    return this.fetch(`/content?productId=${productId}`);
  }

  async fetchContent(id) {
    const data = await this.fetch(`/content?id=${id}`);

    return data[0];
  }

  async createContent(contentData) {
    const data = await this.create('/content', contentData);

    return data[0];
  }

  updateContent(id, data) {
    return this.update(`/content/${id}`, data);
  }

  // ...
  // Upload.

  async upload(entityType, entityId, file) {
    let res = await this.axios.post(`/${entityType}/${entityId}/create-upload-link`, {
      filename: file.name,
      contentType: file.type,
    });

    const { uploadUrl, publicUrl } = res.data;

    await axios(uploadUrl, {
      method: 'PUT',
      data: await file.arrayBuffer(), // Q: Stream to accommodate large files?
      headers: {
        'Content-Type': file.type,
      },
    });

    return publicUrl;
  }

  // ...
  // Publish.

  publish(entityType, entityId) {
    return this.axios.post(`/${entityType}/${entityId}/publish`, {});
  }

  publishProduct(id) {
    return this.publish('products', id);
  }

  publishContent(id) {
    return this.publish('content', id);
  }

  // ...
  // Search.

  async search(query) {
    return this.axios.post('search', query);
  }

  // ...
  // Invite.
  invite(vendorId, email) {
    return this.axios.post(`/vendors/${vendorId}/invite`, {
      email,
      message: '',
    });
  }
}
